const dataAbout = [
    {
        id: 1,
        title: 'Looking for a way to make your event ONE TO REMEBER?',
        desc: ' Look no further than THE BOLD NATION!  We specialize in creating extraordinary and unique events that will blow your mind and will leave a long lasting impression. No matter the size or budget, our team of passionate event professionals, combined with our wide network of vendors and venues, we will ensure that we bring the perfect event management into reality. From creative concept design to flawless execution, The Bold Nation will take your vision and make it a reality - leaving you with an unforgettable experience!',
       
    },
    {
        id: 2,
        title: 'Unbeatable execution ',
        desc: 'When it comes to the best sports event management team, few can compete with the unbeatable execution of Bodybuilding and now on the field of MMA, Weightlifting and Strongman Championships by THE BOLD NATION. We led by a team of experienced professionals, we are emerged as one of the leading event management teams in these sports. With years of experience in executing events, we have a proven track record of delivering successful events that exceed expectations. Our dedicated staff works hard to ensure that every event is run smoothly, from planning through to delivery. We also work closely with our clients to tailor each event according to their requirements and budget. Whether you are planning an amateur or professional championship, Bodybuilding and now on the field of MMA, Weightlifting and Strongman Championships have the skill set and resources needed to ensure your event runs without a hitch.',
       
    }

]

export default dataAbout;