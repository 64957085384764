import React, { useRef, useState } from 'react';
import PageTitle from '../components/pagetitle';
import { BsInstagram} from 'react-icons/bs';
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Contact(props) {
    const notify = () => toast("Wow so easy!");
    const form = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setcontact] = useState("");
    const [message, setmessage] = useState("");
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
          "service_dt9qa0x",
          "template_x5kxfbb",
          form.current,
          "N-GjNGzlCQ-gnccF1"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }

    return (
        <div>
            <PageTitle title='Contact Us' />

            <section className="tf-section tf-contact">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-xl-5 col-md-12">
                            <div className="content-about m-b50 mobie-40" data-aos="fade-right" data-aos-duration="800">
                                <div className="tf-title st2 m-b17">
                                    <h4 className="title">Have a question </h4>
                                </div>
                                <p className="m-r-40">Fill up the Form  and our team will get back to within 24 hrs</p>
                            </div>
                            <form
                            ref={form} onSubmit={sendEmail}
                            action="contact/contact-process.php" className="form-contact" id="contactform" data-aos="fade-right" data-aos-duration="800">
                                <fieldset>
                                    <input
                                           value={name}
                                           onChange={(e) => setName(e.target.value)}
                                    type="text" name="user_name" id="name" placeholder="Name"
                                    required />
                                </fieldset>
                                <fieldset>
                                    <input
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                    type="email" name="user_email" id="mail" placeholder="Email Address"
                                    required />
                                </fieldset>
                                <fieldset>
                                    <input 
                                       value={contact}
                                       onChange={(e) => setcontact(e.target.value)}
                                    type="number" name="user_no" id="phone" placeholder="Phone"
                                    required 
                                 />
                                </fieldset>
                                <fieldset>
                                    <textarea
                                          value={message}
                                          onChange={(e) => setmessage(e.target.value)}
                                          required
                                   
                                    placeholder="Type your Messege" rows="5" tabIndex="4" name="message" className="message" id="message"></textarea>
                                </fieldset>
                                <button className="tf-button btn-effect" type="submit"><span className="boder-fade"></span><span className="effect">Send Message</span></button>
                            </form>
                        </div>
                        <div className="col-xl-7  col-md-12">
                            <div className="contact-details" data-aos="fade-left" data-aos-duration="800">
                                <div className="adress wrap-fx">
                                    <div className="location">
                                        <h6>Location</h6>
                                        <ul>
                                            <li>#167 Sector-1
Chandigarh , 160001</li>
                                        </ul>
                                    </div>
                                    <div className="mail">
                                        <h6>Contact Us</h6>
                                        <ul>
                                            <li>+919371300015</li>
                                            <li>+917837384613</li>

                                            <li>Contactboldnation@gmail.com</li>
                                        </ul>
                                      
                           
                                    </div>
                                    <div>
                                    <ul className='insta-oo'>
                                    <h6>Instagram</h6>
                                           <a className='hov-aa' href="https://www.instagram.com/the.boldnation/?igshid=MDM4ZDc5MmU%3D">
                                            <BsInstagram/>
                                           </a>
                                         
                                        
                                        </ul>
                                    </div>
                                </div>
                                <div className="flat-map wow fadeIn animated" data-wow-delay="0.3ms" data-wow-duration="1000ms">
                                    <iframe title='map' className="map-content" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4457.30210514409!2d144.9550716623184!3d-37.818421643591336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4dd5a05d97%3A0x3e64f855a564844d!2s121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20%C3%9Ac!5e0!3m2!1svi!2s!4v1631871760998!5m2!1svi!2s" width="1720" height="655" allowFullScreen="" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;