import img1 from '../images/common/Atinder-PNG.png';
import img2 from '../images/common/Sahil.jpg';
import img3 from '../images/common/Harjot.jpg';


const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Atinderjeet Singh',
        position: 'Founder Of The Bold Nation',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-facebook-f',
                link: 'https://www.facebook.com/atinderjeet'
            },
            {
                id: 2,
                icon: 'fab fa-instagram',
                link: 'https://www.instagram.com/bodybuilding_alchemist/'
            },
            // {
            //     id: 3,
            //     icon: 'fab fa-envelope',
       
            //     link: '#'
            // },
            // {
            //     id: 4,
            //     icon: 'fab fa-reddit',
            //     link: '#'
            // },
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Sahil Dhingra',
        position: 'Founder Of The Bold Nation',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-facebook-f',
                link: 'https://www.facebook.com/sahil.dhingra.927?mibextid=LQQJ4d'
            },
            {
                id: 2,
                icon: 'fab fa-instagram',
                link: 'https://www.instagram.com/sahildhingra3/'
            },
            // {
            //     id: 3,
            //     icon: 'fa fa-paper-plane',
            //     link: '#'
            // },
            // {
            //     id: 4,
            //     icon: 'fab fa-reddit',
            //     link: '#'
            // },
    
        ]
    },
    
    {
        id: 3,
        img: img3,
        name: 'Harjot Kaur',
        position: 'Executive manager',
        listsocial: [
       
    
        ]
    },
]

export default dataTeam;