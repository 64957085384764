import HomeOne from "./HomeOne";

import AboutOne from "./AboutOne";

import Contact from "./Contact";



const routes = [
  { path: '/', component: <HomeOne />},

  { path: '/about-v1', component: <AboutOne />},




  { path: '/contact', component: <Contact />},


]

export default routes;