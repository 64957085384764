
import img1 from '../images/logo/logo1.png'
import img2 from '../images/logo/logo2.jpg'
import img3 from '../images/logo/logo3.jpg'
import img4 from '../images/logo/logo4.jpg'
import img5 from '../images/logo/logo5.jpeg'
import img6 from '../images/logo/logo6.jpg'
import img7 from '../images/logo/logo7.png'
import img8 from '../images/logo/logo8.png'
import img9 from '../images/logo/logo9.jpeg'
import img10 from '../images/logo/logo10.png'
import img11 from '../images/logo/logo11.png'
import img12 from '../images/logo/logo12.png'
import img13 from '../images/logo/logo13.jpg'
import img14 from '../images/logo/logo14.png'


const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
    {
        img: img4,
    },
    {
        img: img5,
    },
    {
        img: img6,
    },
    {
        img: img7,
    },
    {
        img: img8,
    },
    {
        img: img9,
    },
    {
        img: img10,
    },
    {
        img: img11,
    },
    {
        img: img12,
    },
    {
        img: img13,
    },
    {
        img: img14,
    },
  
]

export default dataPartner;