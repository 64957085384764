import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import WorkItem from './work-item';

Work.propTypes = {
    data: PropTypes.array,
};

function Work(props) {

    const {data} = props;

    const [dataBlock] = useState({
     
        title: 'Become our sponsor',
        desc: 'The Bold Nation is one of the best sports event management teams in bodybuilding and now on the field of MMA, weightlifting and strongman championships. We offer an excellent platform to highlight your brand name by becoming our event sponsor. Our events attract a wide range of audiences from all walks of life, giving you an opportunity to reach out to potential customers. With our expertise in event management, we promise to provide you with maximum exposure and greater visibility for your business.'
    })
    return (
        <section className="tf-section how-we-work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{dataBlock.subtitle}</p>
                            <h4 style={{textAlign:'center'}} className="title m-b17">{dataBlock.title}</h4>
                            <p className="p-r60">{dataBlock.desc} </p>
                        </div>
                    
                    </div>
                    {/* <div> */}


                    {
                        data.map(item => (
                            <div key={item.id} className="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <WorkItem item={item} />
                            </div>
                        ))
                    }
                    {/* </div> */}
                </div>
            </div>
        </section>
    );
}

export default Work;