const dataFaq = [
    {
        id: 1,
        title: 'What other types of events is The Bold Nation expanding into?',
        content: 'The Bold Nation is a company that specializes in producing high-quality, creative events. We have been focusing on sports championships  such as bodybuilding nationals, MMA open nationals, powerlifting open nationals and strongman championship, but are now expanding into other types of events. These include virtual events, music festivals, personal parties, and more. With the help of our experienced team and cutting-edge technology, The Bold Nation is able to create unique experiences for our clients. We strive to deliver innovative solutions that will help make any event memorable.',
        // show: 'show'
    },
    {
        id: 2,
        title: 'What strategies has The Bold Nation implemented to   reach more clients and customers?',
        content: 'The Bold Nation has implemented various strategies to reach more clients and customers. We have adopted a customer-centric approach, focusing on understanding our customer,s needs and providing them with the best possible solutions. We have also made use of digital marketing tools such as SEO and social media marketing to spread our message and reach more people, soon on PPC. Additionally, We have invested in content creation and storytelling to build strong relationships with their customers. All these strategies have helped The Bold Nation reach more clients and customers in an effective manner.',
       
    },
    {
        id: 3,
        title: 'How does The Bold Nation ensure a successful event outcome for its clients?',
        content: 'The Bold Nation is a full-service event planning and management company that specializes in creating successful events for its clients. We are dedicated to ensuring that every event runs smoothly and meets our client,s needs and expectations. From finding the perfect venue to designing the perfect decor, The Bold Nation will provide everything necessary to make sure that your event is a success. We provide services such as venue selection, budgeting, catering, security, entertainment, marketing, staffing, and more. Additionally, we also have an experienced team of professionals who can help you plan out every detail of your event so that it runs without any hiccups. With The Bold Nation,s help you can be sure that your event will be a success!',
    },
    {
        id: 4,
        title: 'What kind of customer feedback has The Bold Nation received from past events?',
        content: 'The Bold Nation has been hosting events for the past few years and has been receiving a lot of positive feedback from their customers. The Bold Nation have been praised for creating an engaging and enjoyable experience, with many customers praising the event staff for their friendliness and professionalism. The Bold Nation also prides itself on its ability to provide a unique and memorable experience to its attendees, with many customers commenting on the quality of the food, entertainment, and atmosphere. Additionally, attendees have praised The Bold Nation for its commitment to making sure everyone feels welcome and included at their events.',
    },
    {
        id: 5,
        title: 'Are there any special benefits for clients who choose to work with the Bold Nation?',
        content: 'Working with the Bold Nation brings a host of benefits for clients. From the ability to access a wide range of creative talent and resources, to the assurance that all projects will be completed on time and within budget. The Bold Nation also offers clients the opportunity to work with experienced professionals who are passionate about their craft and dedicated to delivering top-notch results. Clients can expect personalized attention throughout every step of their project, as well as innovative solutions tailored specifically to their needs. With the Bold Nation, clients can rest assured that they are receiving quality services from a company they can trust.',
    },

]

export default dataFaq;