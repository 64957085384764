const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
      
    },
    {
        id: 2,
        name: 'About',
        links: '/about-v1',
     
    },


    {
        id: 6,
        name: 'Contact',
        links: '/contact'
    },
    
]

export default menus;