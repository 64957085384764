const dataRoadmap = [
    {
        id: 1,
        time: '13/11/2021',
        list: [
            {
                text: 'AT TAGORE THEATRE'
            },
            {
                text: 'NPC NORTH INDIA BODYBUILDING & PHYSIQUE CHAMPIONSHIP '
            },
          
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: '17/4/2022',
        list: [
            {
                text: 'AT INDRADHANUSH AUDITORIUM'
            },
            {
                text: 'NPC NORTH INDIA BODYBUILDING & PHYSIQUE CHAMPIONSHIP'
            },
           
          
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: '17/4/2022',
        list: [
            {
                text: 'AT INDRADHANUSH AUDITORIUM'
            },
            {
                text: 'NPC  MR.TRICITY BODYBUILDING & PHYSIQUE CHAMPIONSHIP'
            },
          
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'COMING SOON',
        list: [
            {
                text: 'AT PANCHKULA'
            },
            {
                text: 'NPC NATIONALS BODYBUILDING & PHYSIQUE CHAMPIONSHIP'
            },
     
          
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'COMING SOON',
        list: [
            {
                text: 'AT PANCHKULA'
            },
            {
                text: 'NPC NATIONALS BODYBUILDING & PHYSIQUE CHAMPIONSHIP'
            },
           
        ],
        positon: 'left'
    },
    {
        id: 6,
        time: 'COMING SOON',
        list: [
            {
                text: 'AT PANCHKULA'
            },
            {
                text: 'NPC TRICITY BODYBUILDING & PHYSIQUE CHAMPIONSHIP'
            },
         
        ],
        positon: 'right'
    },
    {
        id: 7,
        time: 'COMING SOON',
        list: [
            {
                text: 'AT PANCHKULA'
            },
            {
                text: 'STRENGTH MAYHEM STRONGMAN CHAMPIONSHIP'
            },
            
        ],
        positon: 'left'
    },
    {
        id: 8,
        time: 'COMING SOON',
        list: [
            {
                text: 'AT PANCHKULA'
            },
            {
                text: ' STRENGTH MAYHEM POWERLIFTING CHAMPIONSHIP'
            },
        ],
        positon: 'right'
    },
    {
        id: 9,
        time: 'COMING SOON',
        list: [
            {
                text: 'AT PANCHKULA'
            },
            {
                text: ' MMA CHAMPIONSHIP'
            },
        ],
        positon: 'left'
    },

]

export default dataRoadmap;