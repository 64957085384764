import img1 from "../images/slide/side1.JPG";
import img2 from "../images/slide/side2.JPG";
import img3 from "../images/slide/side3.JPG";
import img4 from "../images/slide/side4.JPG";
import img5 from "../images/slide/side5.JPG";
import img6 from "../images/slide/side6.JPG";
import img7 from "../images/slide/side7.JPG";
import img8 from "../images/slide/side8.JPG";
import img9 from "../images/slide/side9.jpeg";
import img10 from "../images/slide/side10.JPG";
import img11 from "../images/slide/side11.jpg";
import img12 from "../images/slide/side12.jpg";
import img13 from "../images/slide/side13.jpg";
import img14 from "../images/slide/side14.jpg";
import img15 from "../images/slide/side15.jpg";
import img16 from "../images/slide/side16.jpg";
import img17 from "../images/slide/side17.jpg";
import img18 from "../images/slide/side18.jpg";
import img19 from "../images/slide/side19.jpg";

const dataProject = [
  {
    id: 1,

    img: img1,
  },
  {
    id: 2,

    img: img2,
  },
  {
    id: 3,

    img: img3,
  },
  {
    id: 4,

    img: img4,
  },
  {
    id: 5,

    img: img5,
  },
  {
    id: 6,

    img: img6,
  },
  {
    id: 7,

    img: img7,
  },
  {
    id: 8,

    img: img8,
  },

  {
    id: 9,

    img: img9,
  },
  {
    id: 10,

    img: img10,
  },
  {
    id: 11,

    img: img11,
  },
  {
    id: 12,

    img: img12,
  },
  {
    id: 13,

    img: img13,
  },
  {
    id: 14,

    img: img14,
  },
  {
    id: 15,

    img: img15,
  },
  {
    id: 16,

    img: img16,
  },
  {
    id: 17,

    img: img17,
  },
  {
    id: 18,

    img: img18,
  },
  {
    id: 19,

    img: img19,
  },
  // {

  // {
  //     id: 20,
  //     title: 'CYBOX #04',
  //     img: img4,
  // },
  // {
  //     id: 21,
  //     title: 'CYBOX #05',
  //     img: img5,
  // },
  // {
  //     id: 22,
  //     title: 'CYBOX #06',
  //     img: img6,
  // },
  // {
  //     id: 23,
  //     title: 'CYBOX #07',
  //     img: img7,
  // },
  // {
  //     id: 24,
  //     title: 'CYBOX #08',
  //     img: img2,
  // },
];

export default dataProject;
