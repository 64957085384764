import React , { useRef, useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom'
import emailjs from "@emailjs/browser";
import img1 from '../../assets/images/background/bg-ft.png'
import img2 from '../../assets/images/background/bg-ft2.png'

import {BsFacebook, BsInstagram } from 'react-icons/bs';
import {AiFillYoutube } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Footer(props) {

    const notify = () => toast("Wow so easy!");
    const form = useRef();
   
    const [email, setEmail] = useState("");

  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
          "service_dt9qa0x",
          "template_gv4lnsh",
          form.current,
          "N-GjNGzlCQ-gnccF1"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }





    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <footer id="footer">
            <div className="footer-main">
                <img src={img1} alt="" className="bg1" />
                <img src={img2} alt="" className="bg2" />
                <div className="container">
                    <ul className="widget-social">
                    <li>
                            <Link to="#">
                            <BsInstagram
                                 style={{fontSize:' 25px'}}/> 
                            </Link>
                        </li>
                        <li>
                        <Link to="#">
                        <AiFillYoutube
                        style={{fontSize:' 30px'}}
                        
                        />
                        </Link>
                        </li>
                     
                        <li>
                            <Link to="#">
                            <BsFacebook
                                 style={{fontSize:' 25px'}}/>
                            </Link>
                        </li>
                     
                 
                    </ul>
                    <h5 className="heading">don’t miss out, join now for early access</h5>
                  
                    <form 
                      ref={form} onSubmit={sendEmail}
                    id="subscribe-form">
                        <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="user_email" type="email" placeholder="Enter your email address" required="" id="subscribe-email" />
                        <button className="tf-button-st2 btn-effect" type="submit" id="subscribe-button"> <span className="effect">Subscribe</span></button>
                    </form>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="wrap-fx">
                        <div className="Copyright">
                            <p>BOLD NATION- ALL rights reserved</p>
                        </div>
                        <ul className="list">
                            {/* <li>
                                <Link to="#">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="#">Cookies</Link>
                            </li>
                            <li>
                                <Link to="#">Terms & Conditions</Link>
                            </li> */}
                            <li>
                                <Link to={'/contact'}>Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>

            {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
        </footer>
    );
}

export default Footer;