
// import bgImg3 from '../images/background/bg-6.JPG';


const dataSlider = [
 

    {
        id: 3,
        title: ' THE BOLD NATION ',
        // desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
        // bgImg: bgImg3,
        classAction: 'three'
    },
   


]

export default dataSlider;